import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";
import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

import css from "../../styles/guide.css";

const UseCaseTeamCalendar = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Online Team Calendar - DayViewer</title>
      <meta
        name="description"
        content="Team Calendar planner system for businesses and teams. DayViewer is an easy to use shared calendar system so you can stay coordinated with your coworkers."
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />
        <Layout className="guide-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h1>Team Calendar Planner</h1>
            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>
          <Row>
            <Col>
              <h2>Online Team Calendar</h2>
              <p>
                It's becoming more important for businesses to manage time and
                tasks centrally. One of the best ways to do this is to use an
                online calendar. Keeping a track of tasks, events and colleagues
                schedules is one of those problems that can be done with a
                simple spreadsheet. However, wouldn't it be better to have a
                centralized, online team system where tasks can be viewed
                anytime by any member, whenever they need to check - and have a
                great way to plan their work?.
              </p>
              <ul>
                <li>Team Task Management </li>
                <li>Rich Entries </li>
                <li>Kanban Boards </li>
                <li>Drag &amp; Drop Fast Planner Interface</li>
                <li>
                  Entry / Task Comments - Keep Conversation Focussed On The Task
                </li>
              </ul>

              <h3>Information Organizer</h3>
              <p>
                It's not only about setting calendar entries, DayViewer also
                incorporates the ability to keep information organized that
                makes a great team scheduler tool. We now have made available to
                you a flexible, clear yet powerful team calendar planner.
              </p>
              <h3>How would DayViewer help our team?</h3>
              <p>
                Creating a team calendar system should be hassle free. DayViewer
                makes it a simple process to set up separate team areas. We call
                them <strong>Team Rooms</strong>. Each room consists of a shared
                but enhanced version of DayViewer Pro with extra features such
                as task assignment.
              </p>
              <h3>
                Using an Online Team Calendar Planner can significantly increase
                productivity.{" "}
              </h3>
              <p>
                Imagine your office working together with less of a need to
                constantly check progress, ability to share information, reduced
                confusion, reduce progress meetings and empowered members who
                are able to self manage, collaborate and work together more
                efficiently - simply by having the information they need
                available on a shared calendar planner platform.
              </p>
              <p>
                It's as easy as opening up your DayViewer Team Room on a daily
                basis to see how things are going, set and review tasks and
                identify workload issues and bottlenecks.
              </p>
              <h3>
                Creating a Task takes seconds - take a look at our{" "}
                <Link to="/tutorial-content/quick-start/">tutorial</Link> to see
                how.
              </h3>
              <p>
                One of the fundamental concepts behind DayViewer is the ability
                to share a calendar with your team. It combines task management
                with time management in a more visual way. This enables the team
                to monitor progress, check overdue deadlines, plan tasks and
                focus discussions on any entry (using entry comments).
              </p>

              <p>
                You can create as many Team Rooms as needed for your
                organization. Providing members with a centralized planner and
                information organizer in one easy to use, consistent interface -
                very low training needed. Unlike a dedicated project management
                system, a Team Room can handle the organization of a variety of
                information your team may need on hand anytime, including
                contacts, notes, journals and calendar entries.
              </p>
              <p>
                Commenting on calendar entries is now also supported - with the
                ability to create a highly focussed discussion per task or other
                entry. The simple consistent interface makes it very easy for
                you to discuss a task or event.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                We believe we have one of the simplest to use interfaces with
                the focus on clarity, flexibility, security and performance.
                Being fairly priced you can remove redundant tools from your
                productivity suite, along with a number of privacy and training
                implications the adoption of a number of tools comes with.
              </p>
              <p>
                Trial a DayViewer Team for free for 14 days by{" "}
                <Button className="homepage-signup-btn">
                  <a
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://app.dayviewer.com/auth/signup",
                        "_self"
                      )
                    }
                  >
                    Signing Up
                  </a>
                </Button>{" "}
                <Link to={`/plans/`}>(Team Room Pricing)</Link>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Team Calendar &amp; Scheduler</h3>
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                  alt="online team calendar"
                  title="DayViewer Team Room Calendar"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Similar Use Cases</h3>
              <Link to={`/use-cases/work-scheduler`}>Work Scheduler</Link>,
              <Link to={`/use-cases/office-diary`}>Office Diary</Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseTeamCalendar;
